<template>
  <div></div>
</template>

<script type="plain/text">
import CartService from "@/scripts/services/cart.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
export default {
  created() {
    if (this.$route.query.recriar) {
      let cartService = new CartService();
      cartService.retryDonation(this.$route.query.recriar).then((data) => {
        if (data) {
          this.$store.dispatch("updateCart", data);
          this.$router.push({
            name: Routes.web.CartLogged,
          });
        } else if (data === false) {
          //CommonHelper.swal("", "Não foi possível localizar o carrinho");
          this.$router.push({
            name: Routes.app.CampaignLandingPage,
          });
        }
      });
    } else {
      //CommonHelper.swal("", "Não foi possível localizar o carrinho");
      this.$router.push({
        name: Routes.app.CampaignLandingPage,
      });
    }
  },
};
</script>

